



































































































@import '~variables'

.tree-controls
  z-index 2
  display flex
  padding 0
  border-radius $border-radius-smooth
  background-color black
  box-shadow $shadow-elevation-2
  .control-group
    display flex
    flex-direction column
    padding 0 $space-1
    border-right 1px solid $dark
    &:last-child
      border-right unset
    .group__title
      padding $space-little 0
      color $silver
      text-align center
      letter-spacing 1.5px
      font-size 0.8rem
    .group__main
      display flex
      justify-content center
      align-items center
      padding $space-little 0
      color white
    &--drag .group__main
      padding-top 1px
    &--center .group__main
      padding-top 2px
    .icon
      cursor pointer

.size-select
  display flex
  .size-select__item
    padding 0 $space-2
    border-radius $border-radius
    font-size $fs-h3
    cursor pointer
    &:hover, &--selected
      background-color white
      color black
