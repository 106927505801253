@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.tree-controls {
  z-index: 2;
  display: flex;
  padding: 0;
  border-radius: 4px;
  background-color: #000;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.tree-controls .control-group {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  border-right: 1px solid #464749;
}
.tree-controls .control-group:last-child {
  border-right: unset;
}
.tree-controls .control-group .group__title {
  padding: 4px 0;
  color: #ddd9d3;
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 0.8rem;
}
.tree-controls .control-group .group__main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  color: #fff;
}
.tree-controls .control-group--drag .group__main {
  padding-top: 1px;
}
.tree-controls .control-group--center .group__main {
  padding-top: 2px;
}
.tree-controls .control-group .icon {
  cursor: pointer;
}
.size-select {
  display: flex;
}
.size-select .size-select__item {
  padding: 0 16px;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
}
.size-select .size-select__item:hover,
.size-select .size-select__item--selected {
  background-color: #fff;
  color: #000;
}
/*# sourceMappingURL=src/app/components/topology/tree-controls.css.map */