























































































































































































































































@import '~variables'

ul, li
  max-height 0
  opacity 0

.node-list
  display flex
  align-items center
  .node-container
    position relative
    display flex
    align-items center
    width fit-content

.open
  max-height none
  opacity 1

// ============== NODE ==============
.node
  display flex
  align-items center
  padding-right $space-2
  padding-left $space-1
  min-width 200px
  height 50px
  border 1px solid black
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-elevation-1
  text-decoration none
  .icon
    display flex
    justify-content center
    align-content center
    margin-right $space-1
    width 25px
    color $dark
  .node-label-container
    display flex
    flex-direction column
    justify-content center
    align-items flex-start
    overflow hidden
    width 100%
    height 100%
    .node-label
      display flex
      margin 0
      margin-right $space-1
      padding-left $space-little
      width fit-content
      height 100%
      color black
      text-overflow ellipsis
      white-space nowrap
      font-weight 700
      font-size $fs-h3
      user-select none
    .node-sub-label
      overflow hidden
      margin 0
      width 100%
      height 100%
      color $grey
      text-overflow ellipsis
      white-space nowrap
      font-size $fs-body
      user-select none

// ========== NODE MODIFIERS ==========
.node
  &.drop
    border 1px solid $positive
    &.dropping
      background-color lighten($positive, 80%)
  &.selected
    background-color black
    color white
    .icon
      color $dark-pink
    .node-label-container
      .node-label
        color white
      .node-sub-label
        color $silver
  &.last
    min-width 150px
    width auto

// ========== OPEN BUTTON ==========
$button_open_size = 24px
$button_open_icon_size = 16px

button.circular
  position absolute
  right -($button_open_size / 2)
  z-index 1
  display flex
  justify-content center
  align-items center
  margin 0
  min-width $button_open_size
  min-height $button_open_size
  width $button_open_size
  height $button_open_size
  outline none
  border unset
  border-radius 50%
  background-color black
  color white
  user-select none
  .q-icon
    min-width $button_open_icon_size
    min-height $button_open_icon_size
    width $button_open_icon_size
    height $button_open_icon_size
    font-size $button_open_icon_size

.node.selected + button.circular
  background-color white
  .q-icon
    color black

// ========== SIZE: SMALL ==========
$xs_button_open_size = 15px
$xs_button_open_icon_size = 10px

.nxs
  .node
    padding 2px
    min-width 100px
    height 20px
    .icon
      margin-right $space-little
    .node-label-container
      .node-label
        font-size 9px
      .node-sub-label
        display none
  button.circular
    right -($xs_button_open_size / 2)
    padding 0
    min-width $xs_button_open_size
    min-height $xs_button_open_size
    width $xs_button_open_size
    height $xs_button_open_size
    border-radius 50%
    .q-icon
      font-size $xs_button_open_icon_size

// ========== SIZE: MEDIUM ==========
$sm_button_open_size = 20px
$sm_button_open_icon_size = 16px

.nsm
  .node
    padding 4px
    min-width 150px
    height 30px
    .icon
      margin-right $space-little
    .node-label-container
      .node-sub-label
        display none
  button.circular
    right -($sm_button_open_size / 2)
    min-width $sm_button_open_size
    min-height $sm_button_open_size
    width $sm_button_open_size
    height $sm_button_open_size
    .q-icon
      font-size $sm_button_open_icon_size

// ========== SIZE: LARGE ==========
.nmd
  .node
    .icon
      margin-right $space-1
    .node-label-container
      .node-label
        align-items flex-end
      .node-sub-label
        display flex
        align-items flex-start
        height 100% // width auto
  button.circular
    right -($sm_button_open_size / 2)
    min-width $sm_button_open_size
    min-height $sm_button_open_size
    width $sm_button_open_size
    height $sm_button_open_size
    .q-icon
      font-size $sm_button_open_icon_size
