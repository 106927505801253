@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
ul,
li {
  max-height: 0;
  opacity: 0;
}
.node-list {
  display: flex;
  align-items: center;
}
.node-list .node-container {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}
.open {
  max-height: none;
  opacity: 1;
}
.node {
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 8px;
  min-width: 200px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  text-decoration: none;
}
.node .icon {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 8px;
  width: 25px;
  color: #464749;
}
.node .node-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.node .node-label-container .node-label {
  display: flex;
  margin: 0;
  margin-right: 8px;
  padding-left: 4px;
  width: fit-content;
  height: 100%;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.1rem;
  user-select: none;
}
.node .node-label-container .node-sub-label {
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  color: #b4b2a9;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  user-select: none;
}
.node.drop {
  border: 1px solid #21ba45;
}
.node.drop.dropping {
  background-color: #cef6d7;
}
.node.selected {
  background-color: #000;
  color: #fff;
}
.node.selected .icon {
  color: #f3afb3;
}
.node.selected .node-label-container .node-label {
  color: #fff;
}
.node.selected .node-label-container .node-sub-label {
  color: #ddd9d3;
}
.node.last {
  min-width: 150px;
  width: auto;
}
button.circular {
  position: absolute;
  right: -12px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  outline: none;
  border: unset;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  user-select: none;
}
button.circular .q-icon {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.node.selected + button.circular {
  background-color: #fff;
}
.node.selected + button.circular .q-icon {
  color: #000;
}
.nxs .node {
  padding: 2px;
  min-width: 100px;
  height: 20px;
}
.nxs .node .icon {
  margin-right: 4px;
}
.nxs .node .node-label-container .node-label {
  font-size: 9px;
}
.nxs .node .node-label-container .node-sub-label {
  display: none;
}
.nxs button.circular {
  right: -7.5px;
  padding: 0;
  min-width: 15px;
  min-height: 15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.nxs button.circular .q-icon {
  font-size: 10px;
}
.nsm .node {
  padding: 4px;
  min-width: 150px;
  height: 30px;
}
.nsm .node .icon {
  margin-right: 4px;
}
.nsm .node .node-label-container .node-sub-label {
  display: none;
}
.nsm button.circular {
  right: -10px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}
.nsm button.circular .q-icon {
  font-size: 16px;
}
.nmd .node .icon {
  margin-right: 8px;
}
.nmd .node .node-label-container .node-label {
  align-items: flex-end;
}
.nmd .node .node-label-container .node-sub-label {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.nmd button.circular {
  right: -10px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}
.nmd button.circular .q-icon {
  font-size: 16px;
}
/*# sourceMappingURL=src/app/components/topology/tree-node.css.map */