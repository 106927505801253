


















































































@import '~variables'

.viewer-tree
  display flex
  flex-direction column
  padding 0.1px // Forbid child margin to overlap
  width 100%
  height @width
  background-color $silver
  .nav-info
    margin $space-2 $space-2 0
