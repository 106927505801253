














































































































































































































































@import '~variables'

$node-separation ?= $space-2
$edge_color ?= black

.htree-container
  position relative
  width 100%
  height 100%
  .tree-controls
    position absolute
    top $space-2
    right $space-2

.htree
  position relative
  overflow hidden
  overflow-y auto
  width 100%

.htree.draggable
  overflow-y hidden

.htree.draggable:hover
  cursor grab

.pan-target.animated
  transition all 0.5s

.pan-target
  position absolute

.htree ul, .htree li
  margin 0
  padding 0

.htree ul
  position relative
  /* transition: all 0.5s; */
  display flex
  flex-direction column
  padding-left $node-separation

.htree li
  position relative
  padding 1px 0 1px $node-separation
  list-style-type none
  /* transition: all 0.5s; */

/* We will use ::before and ::after to draw the connectors */
.htree li::before, .htree li::after
  position absolute
  top 0
  left 0
  width $node-separation
  height 50%
  border-left 1px solid $edge_color
  content ''

.htree li::before
  border-bottom 1px solid $edge_color

.htree li::after
  top 50%
  bottom auto

/* We need to remove left-right connectors from elements without
any siblings */
.htree li:only-child::after, .htree li:only-child::before
  display none

/* Remove space from the top of single children */
.htree li:only-child
  /* padding-left: 0; */

/* Remove left connector from first child and
right connector from last child */
.htree li:first-child::before, .htree li:last-child::after
  border 0 none

/* Adding back the vertical connector to the last nodes */
.htree li:last-child::before
  border-bottom 1px solid $edge_color
  border-radius 0 0 0 $border-radius-smooth

.htree li:first-child::after
  border-top 1px solid $edge_color
  border-radius $border-radius-smooth 0 0 0

.htree li:only-child::after
  display inherit
  border-top 1px solid $edge_color !important
  border-radius 0

/* Time to add downward connectors from parents */
.htree ul ul::before
  position absolute
  top 50%
  left 0
  width $node-separation
  height 0
  border-top 1px solid $edge_color
  content ''

/* Time for some hover effects */
/* We will apply the hover effect the lineage of the element also */
.htree li .node:hover, .htree li .node:hover+ul li .node
  border-color $secondary
  text-decoration none !important
  cursor pointer

/* Connector styles on hover */
.htree li .node:hover+ul li::after, .htree li .node:hover+u, .htree ul:not(.md-list)>li+li
  margin 0
